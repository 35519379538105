import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect() {
    super.connect()
    // Let any ease-in animation complete, then remove it to allow the next animation to play
    setTimeout(() => {
      this.element.classList.remove("ease-in")
    }, 500)
    this.clickCount = 0
    this._bindClickHandler()
  }

  _bindClickHandler() {
    this.element.addEventListener('click', this.handleClick.bind(this))
  }

  handleClick(event) {
    this.clickCount++

    if (this.clickCount === 20) {
      this.element.innerHTML = "<div style=\"text-align: center; padding: 20px;\"><div style=\"color: #718096;\">Wow, you're persistent.</div></div>"
      this.element.removeEventListener('click', this.handleClick)
      return
    }

    if (this.clickCount === 10) {
      this.element.innerHTML = "<div style=\"text-align: center; padding: 20px;\"><div style=\"color: #718096;\">That's enough clicking for one day.</div></div>"
      return
    }

    const animations = ['bounce', 'shake', 'spin', 'pop', 'wobble']
    const randomAnimation = animations[Math.floor(Math.random() * animations.length)]
    
    // Remove any existing animation classes
    animations.forEach(animation => {
      this.element.classList.remove(`animation-${animation}`)
    })
    
    // Add new random animation class
    this.element.classList.add(`animation-${randomAnimation}`)
    
    // Dispatch event for the animation
    this.element.dispatchEvent(new Event('animation:complete'))
  }

  disconnect() {
    this.element.removeEventListener('click', this.handleClick)
  }
}