import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    timeWindowParam: String
  }

  connect() {
    super.connect()
  }

  update(event) {
    // Only handle Enter, Space, and click events
    if (event.type === "keydown") {
      if (event.key !== "Enter" && event.key !== " ") {
        return // Let other keys pass through to the dropdown controller
      }
      event.preventDefault()
      event.stopPropagation()
    }
    
    const timeWindowParam = event.currentTarget.dataset.timeWindowParam || this.timeWindowParamValue
    const url = new URL(window.location)
    url.searchParams.set('tw', timeWindowParam)
    window.location = url.toString()
  }
}