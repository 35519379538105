import ApplicationController from './application_controller'
import {computePosition, autoUpdate, offset, flip, shift, autoPlacement, arrow} from '@floating-ui/dom';

export default class extends ApplicationController {
  static values = {
    text: String,
    id: String,
    url: String,
    placement: String,
    hideArrow: { type: Boolean, default: false }
  }

  connect() {
    super.connect()
    this.tooltip = document.querySelector('#tooltip')
    if (!this.tooltip) return

    this.defaultState = this.tooltip.querySelector('.default_state')
    this.tooltipContent = this.tooltip.querySelector('#tooltip_content')
    if (!this.tooltipContent) return

    this.tooltipContent.setAttribute('role', 'tooltip')
    
    this.element.addEventListener('mouseenter', this.show.bind(this))
    this.element.addEventListener('mouseleave', this.hide.bind(this))
    this.element.addEventListener('focus', this.show.bind(this))
    this.element.addEventListener('blur', this.hide.bind(this))
  }

  disconnect() {
    if (this.cleanup) this.cleanup()
    this.removeArrow()
  }

  createArrow() {
    // Clean up any existing arrows first
    this.removeArrow()

    if (!this.hideArrowValue) {
      this.tooltipArrow = document.createElement('div')
      this.tooltipArrow.id = 'tooltip_arrow'
      this.tooltipArrow.setAttribute('data-popper-arrow', '')
      this.tooltip.insertBefore(this.tooltipArrow, this.tooltip.firstChild)
    }
  }

  removeArrow() {
    // Remove any existing arrows
    const existingArrow = this.tooltip.querySelector('#tooltip_arrow')
    if (existingArrow) {
      existingArrow.remove()
    }
    this.tooltipArrow = null
  }

  show() {
    // Create fresh arrow for this tooltip
    this.createArrow()

    if (this.hasUrlValue) {
      this.tooltipContent.innerHTML = this.defaultState.innerHTML
      const frame = document.createElement('turbo-frame')
      frame.id = 'tooltip_content'
      frame.src = this.urlValue
      this.tooltipContent.replaceChildren(frame)
    } else if (this.hasIdValue) {
      const content = document.querySelector('#' + this.idValue)
      if (content) {
        this.tooltipContent.innerHTML = content.innerHTML
      }
    } else if (this.hasTextValue) {
      this.tooltipContent.innerHTML = this.textValue
    }

    this.tooltip.setAttribute('data-show', '')
    
    Object.assign(this.tooltip.style, {
      display: 'block',
      visibility: 'visible',
      position: 'fixed',
      zIndex: '9999'
    })

    const updatePosition = () => {
      const middleware = [
        offset(6),
        this.hasPlacementValue ? flip() : autoPlacement(),
        shift({padding: 5})
      ]

      // Only add arrow middleware if we have an arrow
      if (this.tooltipArrow) {
        middleware.push(arrow({element: this.tooltipArrow}))
      }

      computePosition(this.element, this.tooltip, {
        placement: this.hasPlacementValue ? this.placementValue : undefined,
        middleware,
        strategy: 'fixed'
      }).then(({x, y, placement, middlewareData}) => {
        Object.assign(this.tooltip.style, {
          left: `${x}px`,
          top: `${y}px`
        })

        // Handle arrow positioning if we have one
        if (this.tooltipArrow && middlewareData.arrow) {
          const {x: arrowX, y: arrowY} = middlewareData.arrow
          const staticSide = {
            top: 'bottom',
            right: 'left',
            bottom: 'top',
            left: 'right'
          }[placement.split('-')[0]]

          Object.assign(this.tooltipArrow.style, {
            left: arrowX != null ? `${arrowX}px` : '',
            top: arrowY != null ? `${arrowY}px` : '',
            right: '',
            bottom: '',
            [staticSide]: '-4px'
          })
        }
      })
    }

    // Initial position
    updatePosition()

    // Keep position updated
    this.cleanup = autoUpdate(
      this.element,
      this.tooltip,
      updatePosition,
      {
        ancestorScroll: true,
        ancestorResize: true,
        elementResize: true,
        layoutShift: true
      }
    )
  }

  hide() {
    if (this.cleanup) {
      this.cleanup()
      this.cleanup = null
    }
    
    this.tooltip.removeAttribute('data-show')
    this.tooltipContent.innerHTML = ''
    Object.assign(this.tooltip.style, {
      display: 'none',
      visibility: 'hidden'
    })

    // Clean up arrow when hiding
    this.removeArrow()
  }
}
