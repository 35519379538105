import ApplicationController from './application_controller'
import DataTable from 'datatables.net-dt'
import 'datatables.net-buttons/js/dataTables.buttons'
import 'datatables.net-buttons/js/buttons.colVis'

export default class extends ApplicationController {
  static values = {
    config: String,
    defaultSortColumnIndex: {type: Number, default: null},
    hiddenColumns: Array,
    stateSave: Boolean,
    pageLength: {type: Number, default: 10}
  }

  _table = null

  connect() {
    super.connect()
    this._initTable()
    this._initColumnFilters()
    this._restoreFilterState()
  }

  disconnect() {
    super.disconnect()
    this._destroyTable()
  }

  _initTable() {
    if (this._table) return

    const tableContent = this.element.tagName === 'TABLE' ? this.element : this.element.querySelector('table')
    if (!tableContent) return

    const thead = tableContent.querySelector('thead')
    const tr = thead ? thead.querySelector('tr') : null
    
    if (!thead || !tr) return

    const columnCount = tr.children.length
    const hiddenColumns = this.hiddenColumnsValue.filter(index => index < columnCount)
    const anyHiddenColumns = hiddenColumns.length > 0

    // Only include the colvis button if there are hidden columns
    const buttons = [
      {
        text: "\u21BA Reset",
        action: (e, dt, node, config) => {
          this.clearState();
        }
      },
      {
        extend: 'collection',
        text: 'Export',
        buttons: ['csv', 'copy']
      },
      'pageLength'
    ]

    if (anyHiddenColumns) {
      buttons.push({
        extend: 'colvis',
        text: 'Show/Hide Columns'
      })
    }

    const order = this.defaultSortColumnIndexValue !== undefined
      ? [[this.defaultSortColumnIndexValue, 'desc']]
      : []

    this._table = new DataTable(tableContent, {
      colReorder: true,
      autoWidth: false,
      order: order,
      stateSave: this.stateSaveValue,
      pageLength: this.pageLengthValue,
      layout: {
        topStart: {
          buttons: buttons
        }
      },
      columnDefs: [
        {
          targets: hiddenColumns,
          visible: false,
          searchable: true
        }
      ]
    });
  }

  _initColumnFilters() {
    // Get filters within this table's wrapper only
    const filters = this.element.querySelectorAll('.table-column-filter')
    if (!this._table) return

    filters.forEach(filter => {
      filter.addEventListener('change', (e) => {
        const targetColumn = filter.dataset.targetColumn
        if (!targetColumn) return

        // Only save filter state if stateSave is true
        if (this.stateSaveValue) {
          localStorage.setItem(`${this.element.id}_${targetColumn}_filter`, e.target.value)
        }

        // Find all columns with matching data-column-name
        this._table
          .columns(`th[data-column-name="${targetColumn}"]`)
          .search(e.target.value)
          .draw()
      })
    })
  }

  _restoreFilterState() {
    // Only restore filter state if stateSave is true
    if (!this.stateSaveValue) return

    // Get filters within this table's wrapper only
    const filters = this.element.querySelectorAll('.table-column-filter')
    
    filters.forEach(filter => {
      const targetColumn = filter.dataset.targetColumn
      if (!targetColumn) return

      const savedValue = localStorage.getItem(`${this.element.id}_${targetColumn}_filter`)
      if (savedValue !== null) {
        filter.value = savedValue
      }
    })
  }

  _initStatusFilter() {
    this._initColumnFilters()
  }

  _destroyTable() {
    if (this._table) {
      this._table.destroy()
      this._table = null
    }
  }

  clearState() {
    if (this._table) {
      // Clear DataTable state
      this._table.state.clear()

      // Clear filter states from localStorage
      const filters = document.querySelectorAll('.table-column-filter')
      filters.forEach(filter => {
        const targetColumn = filter.dataset.targetColumn
        if (targetColumn) {
          localStorage.removeItem(`${this.element.id}_${targetColumn}_filter`)
        }
      })

      window.location.reload()
    }
  }

  get table() {
    return this._table
  }
}
