import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['toggle', 'pane']

  connect() {
    super.connect()
    this.toggleElement = this.hasToggleTarget ? this.toggleTarget : this.element
    this._boundToggle = this.toggle.bind(this)
    this._boundHandleKeyDown = this.handleKeyDown.bind(this)
    this._boundHandleOptionKeyDown = this.handleOptionKeyDown.bind(this)
    this._documentClickHandler = this._handleDocumentClick.bind(this)
    
    this.toggleElement.addEventListener('click', this._boundToggle)
    this.toggleElement.addEventListener('keydown', this._boundHandleKeyDown)
    
    // Add keyboard listeners to all options
    this._addOptionEventListeners()
    
    this.status = 'closed'
    this._initialClose()
  }

  disconnect() {
    if (this.toggleElement) {
      this.toggleElement.removeEventListener('click', this._boundToggle)
      this.toggleElement.removeEventListener('keydown', this._boundHandleKeyDown)
    }
    this._removeOptionEventListeners()
    document.removeEventListener('click', this._documentClickHandler)
  }

  _addOptionEventListeners() {
    const options = this.paneTarget.querySelectorAll('[role="option"]')
    options.forEach(option => {
      option.addEventListener('keydown', this._boundHandleOptionKeyDown)
    })
  }

  _removeOptionEventListeners() {
    const options = this.paneTarget.querySelectorAll('[role="option"]')
    options.forEach(option => {
      option.removeEventListener('keydown', this._boundHandleOptionKeyDown)
    })
  }

  toggle(event) {
    if (event) event.preventDefault()
    this.status === 'open' ? this.closePane() : this.openPane()
  }

  openPane() {
    document.addEventListener('click', this._documentClickHandler)
    this.status = 'open'
    this._toggleClasses(true)
    this.toggleElement.setAttribute('aria-expanded', 'true')
    
    // Focus the first option or the currently selected option
    requestAnimationFrame(() => {
      const selectedOption = this.paneTarget.querySelector('.current[role="option"]')
      const firstOption = this.paneTarget.querySelector('[role="option"]')
      ;(selectedOption || firstOption)?.focus()
    })
  }

  closePane() {
    document.removeEventListener('click', this._documentClickHandler)
    this.status = 'closed'
    this._toggleClasses(false)
    this.toggleElement.setAttribute('aria-expanded', 'false')
    this.toggleElement.focus()
  }

  handleKeyDown(event) {
    switch (event.key) {
      case "Enter":
      case " ":
        event.preventDefault()
        event.stopPropagation()
        this.toggle()
        break
      case "ArrowDown":
        event.preventDefault()
        if (this.status === 'closed') {
          this.openPane()
        } else {
          this._focusNextOption()
        }
        break
      case "ArrowUp":
        event.preventDefault()
        if (this.status === 'closed') {
          this.openPane()
          this._focusLastOption()
        } else {
          this._focusPreviousOption()
        }
        break
      case "Escape":
        if (this.status === 'open') {
          event.preventDefault()
          this.closePane()
        }
        break
      case "Tab":
        if (this.status === 'open') {
          this.closePane()
        }
        break
    }
  }

  handleOptionKeyDown(event) {
    switch (event.key) {
      case "Enter":
      case " ":
        event.preventDefault()
        event.stopPropagation()
        event.target.querySelector('a')?.click()
        break
      case "ArrowDown":
        event.preventDefault()
        this._focusNextOption()
        break
      case "ArrowUp":
        event.preventDefault()
        this._focusPreviousOption()
        break
      case "Escape":
        event.preventDefault()
        this.closePane()
        break
      case "Tab":
        this.closePane()
        break
    }
  }

  _handleDocumentClick(event) {
    if (!this.element.contains(event.target)) {
      this.closePane()
    }
  }

  _toggleClasses(isOpen) {
    const action = isOpen ? 'add' : 'remove'
    const oppositeAction = isOpen ? 'remove' : 'add'
    
    this.paneTarget.classList[action]('open')
    this.paneTarget.classList[oppositeAction]('closed')
    this.toggleElement.classList[action]('open')
    this.toggleElement.classList[oppositeAction]('closed')
  }

  _focusNextOption() {
    const options = Array.from(this.paneTarget.querySelectorAll('[role="option"]'))
    const currentIndex = options.findIndex(option => option === document.activeElement)
    const nextIndex = currentIndex < options.length - 1 ? currentIndex + 1 : 0
    options[nextIndex]?.focus()
  }

  _focusPreviousOption() {
    const options = Array.from(this.paneTarget.querySelectorAll('[role="option"]'))
    const currentIndex = options.findIndex(option => option === document.activeElement)
    const previousIndex = currentIndex > 0 ? currentIndex - 1 : options.length - 1
    options[previousIndex]?.focus()
  }

  _focusLastOption() {
    const options = Array.from(this.paneTarget.querySelectorAll('[role="option"]'))
    options[options.length - 1]?.focus()
  }

  _initialClose() {
    document.removeEventListener('click', this._documentClickHandler)
    this.status = 'closed'
    this._toggleClasses(false)
    this.toggleElement.setAttribute('aria-expanded', 'false')
  }
}
