import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    insightId: String
  }

  loadBackflowDetails(event) {
    const state = event.currentTarget.dataset.state
    const urlParams = new URLSearchParams(window.location.search)
    const timeWindow = urlParams.get("tw")
    const userId = urlParams.get("user_id")
    const teamId = urlParams.get("id")
    
    const params = new URLSearchParams({
      state,
      ...(timeWindow && { tw: timeWindow }),
      ...(userId && { user_id: userId }),
      ...(teamId && { id: teamId })
    })
    
    // Update backflow details
    const url = `/insights/backflow_details?${params}`
    const frame = document.getElementById("backflow_details")
    frame.setAttribute("src", url)
    
    // Update selected state
    this.element.querySelectorAll("tr").forEach(row => {
      row.classList.remove("selected")
    })
    event.currentTarget.classList.add("selected")
  }
}
