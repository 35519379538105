import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["canvas", "score", "startButton", "gameContainer"]
  static values = {
    speed: {type: Number, default: 5},
    spawnRate: {type: Number, default: 1000}
  }

  connect() {
    this.canvas = this.canvasTarget
    this.ctx = this.canvas.getContext("2d")
    this.resetGame()
  }

  resetGame() {
    this.score = 0
    this.arrows = []
    this.keys = {
      ArrowUp: false,
      ArrowDown: false,
      ArrowLeft: false,
      ArrowRight: false
    }
    
    // Set canvas size
    this.canvas.width = 400
    this.canvas.height = 600

    // Clear any existing game loop
    if (this.gameLoop) {
      cancelAnimationFrame(this.gameLoop)
    }
    if (this.spawnInterval) {
      clearInterval(this.spawnInterval)
    }

    // Reset score display
    this.scoreTarget.textContent = "0"

    // Clear canvas
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
  }

  startGame() {
    this.resetGame()
    this.startButtonTarget.style.display = "none"
    this.gameContainerTarget.style.display = "block"
    
    // Start game loop
    this.gameLoop = requestAnimationFrame(this.update.bind(this))
    this.spawnInterval = setInterval(this.spawnArrow.bind(this), this.spawnRateValue)
    
    // Add event listeners
    document.addEventListener("keydown", this.handleKeyDown.bind(this))
    document.addEventListener("keyup", this.handleKeyUp.bind(this))
  }

  disconnect() {
    cancelAnimationFrame(this.gameLoop)
    clearInterval(this.spawnInterval)
    document.removeEventListener("keydown", this.handleKeyDown.bind(this))
    document.removeEventListener("keyup", this.handleKeyUp.bind(this))
  }

  update() {
    // Clear canvas
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
    
    // Draw target zone
    this.ctx.fillStyle = "rgba(255, 255, 255, 0.2)"
    this.ctx.fillRect(0, 500, this.canvas.width, 50)
    
    // Update and draw arrows
    this.arrows = this.arrows.filter(arrow => {
      arrow.y += this.speedValue
      
      // Check if arrow is in target zone
      if (arrow.y >= 500 && arrow.y <= 550) {
        if (this.keys[arrow.direction]) {
          this.score += 100
          this.scoreTarget.textContent = this.score
          return false
        }
      }
      
      // Remove arrows that went off screen
      if (arrow.y > this.canvas.height) {
        return false
      }
      
      // Draw arrow
      this.drawArrow(arrow)
      return true
    })
    
    this.gameLoop = requestAnimationFrame(this.update.bind(this))
  }

  spawnArrow() {
    const directions = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"]
    const direction = directions[Math.floor(Math.random() * directions.length)]
    
    this.arrows.push({
      direction,
      x: this.getArrowX(direction),
      y: -50
    })
  }

  drawArrow(arrow) {
    this.ctx.save()
    this.ctx.translate(arrow.x + 25, arrow.y + 25)
    
    switch (arrow.direction) {
      case "ArrowUp":
        break
      case "ArrowDown":
        this.ctx.rotate(Math.PI)
        break
      case "ArrowLeft":
        this.ctx.rotate(-Math.PI / 2)
        break
      case "ArrowRight":
        this.ctx.rotate(Math.PI / 2)
        break
    }
    
    // Draw arrow
    this.ctx.beginPath()
    this.ctx.moveTo(0, -15)
    this.ctx.lineTo(10, 0)
    this.ctx.lineTo(5, 0)
    this.ctx.lineTo(5, 15)
    this.ctx.lineTo(-5, 15)
    this.ctx.lineTo(-5, 0)
    this.ctx.lineTo(-10, 0)
    this.ctx.closePath()
    
    this.ctx.fillStyle = "white"
    this.ctx.fill()
    this.ctx.restore()
  }

  getArrowX(direction) {
    switch (direction) {
      case "ArrowLeft": return 50
      case "ArrowDown": return 150
      case "ArrowUp": return 250
      case "ArrowRight": return 350
    }
  }

  handleKeyDown(event) {
    if (this.keys.hasOwnProperty(event.key)) {
      event.preventDefault()
      this.keys[event.key] = true
    }
  }

  handleKeyUp(event) {
    if (this.keys.hasOwnProperty(event.key)) {
      event.preventDefault()
      this.keys[event.key] = false
    }
  }
}
