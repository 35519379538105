import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["text", "icon"]
  static values = {
    title: { type: String, default: null }
  }

  connect() {
    this.updateText = this.updateText.bind(this)
    setTimeout(() => {
      this.updateText()
      this.iconTarget.style.opacity = "1"
    }, 100)
    document.addEventListener("turbo:load", this.updateText)
  }

  disconnect() {
    document.removeEventListener("turbo:load", this.updateText)
  }

  updateText() {
    if (!this.hasTextTarget) return

    let newText = "Back"
    if (this.hasTitleValue && this.titleValue) {
      newText = `Back to ${this.titleValue}`
    } else {
      const previousTitle = localStorage.getItem("previousPageTitle")
      if (previousTitle) {
        newText = `Back to ${previousTitle}`
      }
    }

    if (this.textTarget.textContent !== newText) {
      this.textTarget.style.opacity = "0"
      setTimeout(() => {
        this.textTarget.textContent = newText
        this.textTarget.style.opacity = "1"
      }, 200)
    }
  }

  goBack() {
    history.back()
  }
} 