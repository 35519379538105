import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["section", "icon"]

  connect() {
    super.connect()
    this.initializeAccordions()
  }

  initializeAccordions() {
    // Close all ticket sections initially
    this.sectionTargets.forEach(section => {
      if (section.dataset.type === "ticket") {
        section.style.maxHeight = "0px"
      } else if (section.dataset.type === "main") {
        section.style.maxHeight = "1000px"
      }
    })
  }

  toggle(event) {
    const clickedElement = event.currentTarget
    const sectionId = clickedElement.dataset.sectionId
    const section = this.sectionTargets.find(target => target.id === sectionId)
    const icon = this.iconTargets.find(target => target.dataset.sectionId === sectionId)
    
    if (section && icon) {
      const isVisible = section.style.maxHeight !== "0px"
      section.style.maxHeight = isVisible ? "0px" : `${section.scrollHeight}px`
      icon.style.transform = isVisible ? "rotate(0deg)" : "rotate(180deg)"
    }
  }
} 